import { connectRequest, productRequests } from '@tools/wr-catalog-base'
import ProductList from './product-list'

export default connectRequest((props) => {
  const { productIdsToRender } = props
  const queries = {}
  ;(productIdsToRender || []).forEach((id) => {
    queries[`fetchProduct:${id}`] = productRequests.fetchProductById(id)
  })
  return queries
})(ProductList)
