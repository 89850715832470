import { sortBy, uniqBy, reverse } from 'lodash'
import { context } from '@tools/wr-catalog-base'

const createProductFilterRegex = (searchFilter) => {
  if (!searchFilter) return null

  const parts = searchFilter.split(/\s+/).map((part) => part.toLowerCase())
  return {
    test: (text) => {
      text = text.toLowerCase()
      return parts.every((part) => text.includes(part))
    },
  }
}

export const filterProducts = (catalogs, searchFilter, searchableKeys, productsToDisplay = [], priceFilter) => {
  const { categoryFilters, activeFilters = [], selectedIndustries } = context.state
  const activeCategoryFilters = categoryFilters.length > 0
  const { categories } = context.state
  const updatedProducts = []

  const searchPattern = createProductFilterRegex(searchFilter)

  catalogs.forEach((catalog) => {
    let products = Array.isArray(catalog) ? catalog : catalog.contents ? catalog.contents.items : null

    if (!products) return

    if (activeFilters.length) {
      products = products.filter((prod) => {
        let isInCategory = false

        if (!prod.filters) return isInCategory

        prod.filters.forEach((f) => {
          if (activeFilters.indexOf(f.id) !== -1) {
            isInCategory = true
          }
        })

        return isInCategory
      })
    }

    // Insdustry filtering
    if (Object.values(selectedIndustries).find(Boolean)) {
      products = products.filter((prod) => {
        return prod.categories.some((cat) => selectedIndustries[cat.name])
      })
    }

    if (productsToDisplay.length > 0) {
      products = products.filter((product) => productsToDisplay.includes(product.id))
    }

    if (searchFilter) {
      products = products.filter((v) => searchPattern.test(searchableKeys.map((k) => v[k]).join('')))
    }

    if (priceFilter && context.state.priceFilter.maxValue) {
      products = products.filter((product) => {
        const priceValue = parseInt(product.pricing.value)
        return priceValue >= context.state.priceFilter.values[0] && priceValue <= context.state.priceFilter.values[1]
      })
    }

    categories.forEach((category) => {
      if (activeCategoryFilters && !categoryFilters.find((filter) => filter === category.id)) {
        return
      }

      const productToAdd = products.filter((product) => {
        if (category.id === 'category-undefined' && product.categories.length === 0) {
          return product
        } else {
          return product.categories.find((cat) => category.id === cat.id)
        }
      })
      const categoryDefined = updatedProducts.find((cat) => cat[category.id])

      if (productToAdd.length) {
        if (categoryDefined) {
          updatedProducts.forEach((cat) => {
            if (cat[category.id]) {
              const prod = Array.isArray(productToAdd) ? productToAdd : [productToAdd]
              cat[category.id].push(...prod)
            }
          })
        } else {
          updatedProducts.push({
            [category.id]: productToAdd,
          })
        }
      }
    })

    if (categories.length === 0) {
      updatedProducts.push({
        'category-undefined': products,
      })
    }
  })

  return {
    products: updatedProducts,
    categories,
  }
}

export const retrieveProducts = (products = {}, searchFilter, sortByAccelerators = false) => {
  let mappedProducts = []
  const searchPattern = createProductFilterRegex(searchFilter)

  products.map((productCategory) =>
    Object.keys(productCategory).map((key) => productCategory[key].forEach((item) => mappedProducts.push(item))),
  )

  mappedProducts = uniqBy(mappedProducts, 'id')
  if (sortByAccelerators) {
    mappedProducts = reverse(
      sortBy(mappedProducts, (v) => {
        const row = v.rows.find((r) => r.options && r.options.tag && r.options.tag.includes('bundle_count'))
        if (row) {
          return parseInt(row.control, 10) || 0
        } else {
          return 0
        }
      }),
    )
  } else {
    mappedProducts = sortBy(mappedProducts, 'order')
  }
  if (searchFilter) {
    mappedProducts = sortBy(mappedProducts, (p) => (searchPattern.test(p.name) ? 0 : 1))
  }
  return mappedProducts
}

export const getFilters = (filters) => {
  const blacklistedFilters = ['Manufacturing']
  const capabilityFilters = [
    'Recently Updated',
    'FOMT - Building Design/Civil Engineering',
    'FOMT - Building Design / Civil Engineering',
    'FOMT - Manufacturing',
  ]
  const isCapabilityCatalogSelected = context.state.selectedCatalogId === context.constants.client.catalogIds[1]

  if (isCapabilityCatalogSelected) {
    return uniqBy(
      filters.filter((v) => capabilityFilters.includes(v.label) && !blacklistedFilters.includes(v.label)),
      'id',
    )
  } else {
    return uniqBy(
      filters.filter(
        (v) =>
          v.label === 'Recently Updated' ||
          (!capabilityFilters.includes(v.label) && !blacklistedFilters.includes(v.label)),
      ),
      'id',
    )
  }
}

export const getProductRow = (product, by, name, strict = false) => {
  const getBy = {
    label: () => product.rows.find((row) => row.label === name),
    rowType: () => product.rows.find((row) => row.rowType === name),
    tag: () =>
      product.rows.find((row) =>
        row.options.tag ? (strict ? row.options.tag === name : row.options.tag.includes(name)) : null,
      ),
  }

  return Object.keys(getBy).includes(by) ? getBy[by]() : null
}
