import React from 'react'
import { Label, getLocalizedProperty } from '@tools/wr-catalog-base'

const HTMLComponent = ({ row }) => {
  const HTMLcontent = getLocalizedProperty(row, 'control')

  const divRef = React.useRef(null)
  React.useEffect(() => {
    if (divRef.current) {
      const iframes = Array.from(divRef.current.querySelectorAll('iframe'))
      iframes.forEach((iframe) => {
        if (iframe.height) {
          iframe.style.height = iframe.height + 'px'
        }
      })
      const links = Array.from(divRef.current.querySelectorAll('a'))
      links.forEach((a) => {
        a.target = '_blank'
      })
    }
  }, [HTMLcontent])

  return (
    <div>
      <Label>{getLocalizedProperty(row, 'label')}</Label>
      <div ref={divRef} dangerouslySetInnerHTML={{ __html: HTMLcontent }} />
    </div>
  )
}

export default HTMLComponent
