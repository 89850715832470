import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './debug-styled'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { TrackJS } from 'trackjs'

TrackJS.install({
  token: 'e7442525f69e441eb1a8441efdce14fd',
  application: 'autodesk',
})

// Redirect to new url if we're running on the old one
if (
  process.env.NODE_ENV !== 'development' &&
  !window.location.host.includes('dev') &&
  window.location.host.includes('servicecatalogue.autodesk.com') &&
  process.env.REACT_APP_REDIRECT_ENABLED === 'true'
) {
  window.location.href = `https://acceleratorcatalog.autodesk.com/${window.location.hash}`
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
