import React from 'react'
import { Proposals, withContext, Link, Row, Grid, SpacedCol } from '@tools/wr-catalog-base'
import { submitProposal } from '../../utils/proposals/submit-proposal'

const ProposalsView = ({ context }) => {
  if (!context.state.isLoggedIn) {
    return (
      <Grid>
        <Row>
          <SpacedCol xs={12}>
            <span>You must be logged in to view this page.</span>
            <span>
              You can log in <Link to="/login">here</Link>.
            </span>
          </SpacedCol>
        </Row>
      </Grid>
    )
  }

  return (
    <Grid>
      <Row>
        <SpacedCol xs={12}>
          <Proposals submitProposal={submitProposal} asQuoteWord asQuotePdf />
        </SpacedCol>
      </Row>
    </Grid>
  )
}

export default withContext(ProposalsView)
