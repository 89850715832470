import React, { useState } from 'react'
import classNames from 'classnames'
import { styled, Row, Col } from '@tools/wr-catalog-base'

const SplitViewContainer = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
    }
  }

  .active-view {
    animation: fadeIn 1s forwards;
  }

  .hidden-view {
    display: none;
  }
`

const Switcher = styled.ul`
  display: flex;

  li {
    text-align: center;
    flex: 1;
    padding: 15px;
    transition: 300ms all;
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary};
    font-weight: 500;
    cursor: pointer;
    background-color: #fff;
    flex: 5;
  }

  li:first-child {
    border-right: 1px solid ${({ theme }) => theme.palette.primary};
  }

  .active {
    background-color: ${({ theme }) => theme.palette.primary};
    color: #fff;
    transition: 300ms all;
  }

  li:hover:not(.active):not(.between-label) {
    opacity: 0.7;
  }

  li.between-label {
    flex: 1;
    cursor: default;
    border-left: none;
    border-right: none;
  }
`

function SplitView(props) {
  const [activeView, setActiveView] = useState(0)

  const { labels, className, omitHiddenViews } = props

  const views = typeof props.views === 'function' ? props.views(activeView) : props.views

  return (
    <SplitViewContainer className={classNames('container', className)}>
      <Row middle="xs" center="xs">
        <Col lg={8} md={10} sm={12} xs={12}>
          <Switcher className="split-view-switcher">
            {labels.map((label, i) => (
              <li key={i} className={activeView === i ? 'active' : ''} onClick={() => setActiveView(i)}>
                {label}
              </li>
            ))}
          </Switcher>
        </Col>
      </Row>

      {views.map(
        (view, i) =>
          (i === activeView || !omitHiddenViews) && (
            <div key={i} className={activeView === i ? 'active-view' : 'hidden-view'}>
              {view}
            </div>
          ),
      )}
    </SplitViewContainer>
  )
}

export default SplitView
