import React, { Component } from 'react'
import { Grid, Row, Col, Hero, styled, context, updateContextState } from '@tools/wr-catalog-base'
import IndustryToggles from '../../components/industry-toggles'
import SubHero from './sub-hero'
import CustomProductList from '../../components/product-list'
import SplitView from 'components/split-view'

const SPLIT_VIEW_LABELS = ['Search/Explore Accelerators', 'Search/Explore Capabilities']

const Container = styled.div`
  .split-view-switcher {
    border-bottom: 1px solid #0477a8;
  }

  .split-view-switcher li {
    border: none;
    background-color: transparent;
    color: #333;
  }

  .split-view-switcher li:first-child {
    border-right: none;
  }

  .split-view-switcher li.active {
    border-bottom: 3px solid #0477a8;
    background-color: transparent;
    color: #0477a8;
  }
`

class List extends Component {
  componentDidMount() {
    context.actions.logIndexTransaction()
    updateContextState((state) => {
      state.selectedCatalogId = context.constants.client.catalogIds[0]
    })
  }

  // switches selectedCatalogId based on chosen view
  handleClickSplitView = (e) => {
    const selectedViewIndex = SPLIT_VIEW_LABELS.indexOf(e.target.innerText)
    if (selectedViewIndex !== -1) {
      updateContextState((state) => {
        state.selectedCatalogId = context.constants.client.catalogIds[selectedViewIndex]
        state.activeFilters = []
      })
    }
  }

  render() {
    return (
      <Container>
        <Hero />
        <SubHero />
        <Grid>
          <Row middle="xs" center="xs">
            <Col lg={8} xs={12}>
              <IndustryToggles />
            </Col>
          </Row>
        </Grid>
        <div onClick={this.handleClickSplitView}>
          <SplitView
            labels={SPLIT_VIEW_LABELS}
            omitHiddenViews
            views={[
              <CustomProductList renderProductCount productCountLabel="Accelerators" />,
              <CustomProductList
                renderProductCount
                productCountLabel="Capabilities"
                hidePriceAndDurationIfZero
                sortByAccelerators
              />,
            ]}
          />
        </div>
      </Container>
    )
  }
}

export default List
