import React, { PureComponent } from 'react'
import { MediumText, BoldText } from '@tools/wr-catalog-base'
import CustomProductList from '../product-list'
import styles from './bundles.module.css'

const sortBundles = (a, b) => {
  const aSign = a.name.split(' ').pop()
  const bSign = b.name.split(' ').pop()

  return aSign.localeCompare(bSign)
}

const sortProducts = (productsToShow, activeSorting) => {
  // Just make sure no sorting is applied
  // The products are supposed to be sorted correctly by the retrieveProducts function
  return productsToShow
}

class Bundles extends PureComponent {
  renderBundle = (bundle) => {
    return (
      <div className={styles.bundleContainer} key={bundle.id}>
        <CustomProductList
          productIdsToRender={bundle.childIds}
          noCategoryFilter
          noAnimation
          sortProducts={sortProducts}
          useCarousel={{
            pageSize: 3,
            pageJumpSize: 3,
          }}
          renderContent={(renderParts, renderOptions) => {
            const { productsToShow } = renderOptions
            return (
              <>
                <MediumText>
                  <BoldText>
                    {bundle.name} ({productsToShow.length} accelerators)
                  </BoldText>
                </MediumText>
                {renderParts.toolbar}
                {renderParts.categories}
                {renderParts.priceFilter}
                {renderParts.productList}
                {renderParts.noProductsMessage}
              </>
            )
          }}
        />
      </div>
    )
  }

  render() {
    const { row } = this.props

    return (
      <div>
        {row.options.bundles
          .filter((v) => v.childIds && v.childIds.length)
          .slice()
          .sort(sortBundles)
          .map(this.renderBundle)}
      </div>
    )
  }
}

export default Bundles
