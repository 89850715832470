import React, { Component } from 'react'
import {
  WrRoot,
  configureContext,
  extendLocale,
  Navbar,
  Footer,
  Button,
  Router,
  Dropdown,
  Link,
  withContext,
  styled,
  Modal,
  productUtils,
  Progress,
  ErrorText,
  context,
  connectRequest,
  updateContextState,
  generateTransaction,
} from '@tools/wr-catalog-base'
import { FiMenu, FiX, FiUser } from 'react-icons/fi'
import { IoMdArrowDropdown } from 'react-icons/io'
import Login from './views/Login'
import config from './config'
import en from './en'
import './App.css'

extendLocale(en)
configureContext({ ...config })

window.wrContext = context

const UserTrigger = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: #333;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .user-icon,
  .arrow-icon {
    vertical-align: middle;
  }

  .user-icon {
    margin-right: 5px;
  }

  .arrow-icon {
    margin-right: 5px;
  }
`

const DropdownContainer = styled.div`
  display: inline-block;
  margin-left: 10px;

  button:focus {
    outline: none;
  }

  @media only screen and (max-width: 992px) {
    margin-left: 0;
    padding: 20px 0;
  }
`

const AutodeskCatalog = styled.div`
  button {
    box-shadow: none;
    outline: none;
  }

  .navbar-container {
    overflow: visible;
  }

  .navbar-container a {
    color: #333;
  }

  .navbar-container a:hover,
  .dropdown-trigger > div:hover,
  .dropdown-trigger > div:hover svg {
    opacity: 1;
    color: #0696d7;
  }

  .dropdown-trigger > div,
  .dropdown-trigger > div:hover,
  .dropdown-trigger > div svg,
  .dropdown-trigger > div:hover svg {
    transition: 0.2s linear color;
  }

  .hero {
    text-align: center;
  }

  .hero h2 {
    font-size: 42px;
  }

  .hero h3 {
    font-size: 22px;
    margin: 0;
    display: inline-block;
  }

  .mobile-menu {
    display: none;
  }

  .mobile-menu-list {
    margin-top: 65px;
  }

  .mobile-menu-list a,
  .mobile-menu-list .dropdown-trigger > div,
  .mobile-menu-list .dropdown-trigger svg {
    transition: opacity 0.2s;
    color: #021a32;
  }

  .mobile-menu-list a:hover,
  .mobile-menu-list .dropdown-trigger:hover {
    transition: opacity 0.2s;
    opacity: 0.7;
    color: #021a32;
  }

  .mobile-menu-list a + div,
  .mobile-menu-list a + div + div {
    display: block;
  }

  .mobile-menu-list .dropdown-trigger:hover svg {
    color: #021a32;
  }

  .dropdown-list {
    width: 200px;
    right: 0;
  }

  .nav-products {
    width: 300px;
  }

  footer {
    margin-top: 50px;
  }
`

const OffsetTop = styled.div`
  margin-top: ${({ theme }) => theme.components.navbar.height};
`

const MenuList = ({ username, logout, price, isLoggedIn }) => (
  <>
    {isLoggedIn && (
      <>
        <Link secondary to="/proposals">
          My Proposals
        </Link>
        <DropdownContainer>
          <Dropdown>
            {({ DropdownTrigger, DropdownList }) => (
              <>
                <DropdownTrigger>
                  <UserTrigger>
                    <FiUser className="user-icon" size={20} />
                    {username}
                    <IoMdArrowDropdown className="arrow-icon" size={20} />
                  </UserTrigger>
                </DropdownTrigger>
                <DropdownList>
                  <ul>
                    <li onClick={logout}>
                      <Button fullWidth primary={false}>
                        Log out
                      </Button>
                    </li>
                  </ul>
                </DropdownList>
              </>
            )}
          </Dropdown>
        </DropdownContainer>
      </>
    )}

    {!isLoggedIn && (
      <Link secondary to="/login">
        Log in
      </Link>
    )}
  </>
)

class App extends Component {
  state = {
    menuOpened: false,
  }

  getTotalPrice = () => {
    const { cart } = this.props.context.state
    let totalPrice = 0
    const withoutDividing = true

    Object.keys(cart).forEach((key) => {
      const product = cart[key]

      totalPrice += productUtils.getPrice(product, withoutDividing)
    })

    return totalPrice
  }

  toggleMenu = () => {
    this.setState({ menuOpened: !this.state.menuOpened })
  }

  render() {
    const { menuOpened } = this.state
    const { context, oauthTokenRequest } = this.props
    const { user } = context.state
    const username = context.state.isLoggedIn && user ? user.email.substring(0, user.email.lastIndexOf('@')) : ''
    const logout = context.actions.logout
    const price = this.getTotalPrice()
    const isLoggedIn = context.state.isLoggedIn

    if (oauthTokenRequest.pending) {
      return <Progress />
    } else if (oauthTokenRequest.rejected) {
      return <ErrorText>There was an error while processing your request. Please try again later.</ErrorText>
    }

    return (
      <AutodeskCatalog className="autodesk-catalog">
        <Navbar>
          <div className="desktop-menu">
            <MenuList username={username} logout={logout} price={price} isLoggedIn={isLoggedIn} />
          </div>
          <div className="mobile-menu">
            {!menuOpened && <FiMenu onClick={this.toggleMenu} size={30} color="#fff" />}
            {menuOpened && <FiX onClick={this.toggleMenu} size={30} color="#fff" />}
          </div>
        </Navbar>
        {menuOpened && (
          <div className="mobile-menu-list">
            <div className="container">
              <MenuList username={username} logout={logout} price={price} />
            </div>
          </div>
        )}
        {!isLoggedIn && !context.constants.autodeskAccessToken ? (
          <OffsetTop>
            <Login />
          </OffsetTop>
        ) : (
          <Router />
        )}
        <Footer />
        <Modal />
      </AutodeskCatalog>
    )
  }
}

const ConnectedApp = connectRequest((props) => {
  const urlParams = new window.URLSearchParams(window.location.search)
  const code = urlParams.get('code')

  return {
    // Sends a request to the WR API with the code that should be provided here as a query param
    // Receives a token from the Autodesk backend
    oauthTokenRequest: code
      ? process.env.NODE_ENV === 'development'
        ? {
            value: new Promise((resolve) => {
              window.setTimeout(resolve, 3000)
            }),
            then: () => {
              updateContextState((state) => {
                state.isLoggedIn = true
                state.user = {
                  email: 'autodesk@workrails.com',
                }
              })

              context.persistentStorage.set('autodeskAccessToken', 'localtest')
              window.history.replaceState({}, document.title, '/')

              const redirectPath = window.sessionStorage.getItem('redirectPath')
              if (redirectPath) {
                context.history.push(redirectPath.slice(1))
                window.sessionStorage.removeItem('redirectPath')
              } else {
                context.history.push(context.getRoutePath('index'))
              }
            },
          }
        : {
            url: `${context.constants.host}/beta/sso/oauth2/token`,
            method: 'POST',
            body: JSON.stringify({
              data: {
                code,
                redirectUri: window.location.origin,
                clientId: process.env.REACT_APP_AD_CLIENT_ID,
                remoteUrl: process.env.REACT_APP_AD_SAML_TOKEN_ENDPOINT,
              },
            }),
            then: (v) => {
              if (v && v.data && v.data.accessToken) {
                updateContextState((state) => {
                  state.isLoggedIn = true
                  state.user = {
                    email: 'autodesk@workrails.com',
                  }
                })

                context.persistentStorage.set('autodeskAccessToken', v.data.accessToken)
                window.history.replaceState({}, document.title, '/')

                try {
                  const tokenInfo = JSON.parse(window.atob(v.data.idToken.split('.')[1]))
                  const { email, ctry, organization, department } = tokenInfo
                  context.persistentStorage.set('autodeskEmail', email)
                  context.persistentStorage.set('autodeskCtry', ctry)
                  context.persistentStorage.set('autodeskOrganization', organization)
                  context.persistentStorage.set('autodeskDepartment', department)

                  generateTransaction({ action: 'login', type: 'user' })
                } catch (ex) {
                  console.error('Login logging error', ex)
                }
                const redirectPath = window.sessionStorage.getItem('redirectPath')
                if (redirectPath) {
                  context.history.push(redirectPath.slice(1))
                  window.sessionStorage.removeItem('redirectPath')
                } else {
                  context.history.push(context.getRoutePath('index'))
                }
              }
            },
          }
      : { value: {} },
  }
})(withContext(App))

class AppRoot extends Component {
  render() {
    return (
      <WrRoot>
        <ConnectedApp />
      </WrRoot>
    )
  }
}

export default AppRoot
