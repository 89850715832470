import React, { Component } from 'react'
import BuildQuote from './build-quote'
import { FaCheck } from 'react-icons/fa'
import { MdReplay } from 'react-icons/md'
import {
  styled,
  withContext,
  Card,
  Grid,
  Progress,
  Button,
  MutedText,
  MediumText,
  EngravedText,
  CenteredText,
  PrimaryText,
  i18n,
  getLocalizedProperty,
  connectRequest,
  modules,
} from '@tools/wr-catalog-base'

const { requests } = modules.decisionTree

const HeaderContainer = styled.p`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary};
  font-size: ${({ theme }) => theme.components.decisionTree.questions.fontSize};
`

const DecisionTreeContainer = styled.div`
  display: flex;
  flex-direction: column;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .toggleAnimation {
    animation: fadeOut 0s;
    animation: fadeIn 0.5s;
  }
`

const DecisionTreeBody = styled.div`
  flex: 0 1 auto;
  overflow-y: auto;

  p {
    font-size: 16px;
    line-height: 22px;
  }

  .question {
    margin: 10px 0;
  }

  .question-final {
    border-bottom: 1px solid ${({ theme }) => theme.components.card.borderColor};
    padding-bottom: 25px;

    > p {
      font-weight: ${({ theme }) => theme.components.decisionTree.questions.finalQuestionFontWeight};
      font-size: ${({ theme }) => theme.components.decisionTree.questions.finalQuestionFontSize};
    }
  }

  .no-quote {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;

    p {
      padding-bottom: 10px;
    }

    p:last-child {
      padding-bottom: 0;
    }
  }

  .answer-button {
    width: ${({ theme }) => theme.components.decisionTree.answers.width};
    flex: 1 0 auto;
    line-height: 30px;
    margin: 10px 0 5px 0;
    text-align: left;
    transition: all 400ms;
    color: ${({ theme }) => theme.components.decisionTree.answers.color};
    border: ${({ theme }) => theme.components.decisionTree.answers.border};
    background-color: ${({ theme }) => theme.components.decisionTree.answers.backgroundColor};
    display: flex;
  }

  .answer-button:focus {
    outline: none;
  }

  .answer-button:hover {
    transition: all 400ms;
    box-shadow: ${({ theme }) => theme.components.decisionTree.answers.hoverBoxShadow};
  }

  .answer-button.selected {
    background-color: ${({ theme }) => theme.components.decisionTree.answers.selectedAnswer};
    border: ${({ theme }) => theme.components.decisionTree.answers.selectedAnswerBorder};
    color: ${({ theme }) => theme.components.decisionTree.answers.selectedAnswerColor};
  }

  ${({ theme }) =>
    theme.components.decisionTree.dynamicLayout &&
    `
    display: inline-flex;
    flex-direction: column;

    .answer-button {
      flex: 1;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex-direction: row;
      flex-wrap: wrap;

      .answer-button {
        flex: 1 0 auto;
        width: 100%;
      }
    }
  `}
`

const DecisionTreeFooter = styled.div`
  .start-over svg {
    padding-bottom: 3px;
    margin-right: 3px;
  }

  .start-over,
  .dropdown {
    display: inline-block;
  }

  .send-to-vms {
    padding: 12px 20px 13px;
    margin-left: 10px;
  }

  .dropdown,
  .dropdown-trigger,
  .dropdown-list {
    max-width: 220px;
  }

  .dropdown-list {
    z-index: 100;
  }

  .dropdown-list button {
    width: 100%;
    border-radius: 0;
  }

  .dropdown-trigger button {
    width: 100%;
  }

  button {
    margin-right: 10px;
    width: auto;
    padding: 10px 20px;
    border-radius: ${({ theme }) => theme.components.decisionTree.footer.buttonBorderRadius};

    svg {
      vertical-align: middle;
    }
  }
`

const AnswerText = styled.span`
  flex: 1 1 auto;
  font-weight: ${({ theme }) => theme.components.decisionTree.answers.fontWeight};
`

const AnswerIcon = styled.span`
  flex: 0 0 auto;

  svg {
    vertical-align: middle;
  }
`

const AnswersSummary = styled.div`
  .question-summary {
    margin-top: 25px;
    color: ${({ theme }) => theme.components.decisionTree.answers.questionSummaryColor};
    padding-bottom: 5px;
  }

  .answer-summary {
    color: ${({ theme }) => theme.components.decisionTree.answers.answerSummaryColor};
  }

  p {
    font-weight: 500;
  }
`

const AnswerHelp = styled.span`
  font-weight: normal;
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin: 5px 0;
  color: ${({ theme }) => theme.components.decisionTree.answers.helpText.color};
`

const RenderHelpText = ({ answer }) => <AnswerHelp dangerouslySetInnerHTML={{ __html: answer.help }} />

class DecisionTree extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentQuestion: props.flow,
      selectedAnswers: {},
      buildQuote: false,
      animateContent: true,
    }

    this.currentQuestion = {}
    this.currentQuestionNumber = 1
    this.currentAnswer = {}
  }

  static defaultProps = {
    buildQuoteLabel: i18n('decisionTree.quoteBuilder.buildQuote'),
    RenderHelpText,
  }

  componentDidMount() {
    if (this.props.getDecisionTreeReset) {
      this.props.getDecisionTreeReset(this.resetDecisionTree)
    }
  }

  resetDecisionTree = () => {
    this.currentQuestion = {}
    this.currentQuestionNumber = 1
    this.currentAnswer = {}

    this.setState({
      currentQuestion: this.props.flow,
      selectedAnswers: {},
      buildQuote: false,
      animateContent: true,
    })

    if (this.props.onReset) {
      this.props.onReset()
    }
  }

  answerSelected = (answer, question) => {
    let selectedAnswers = {}

    if (
      this.state.selectedAnswers[this.currentQuestionNumber] &&
      this.state.selectedAnswers[this.currentQuestionNumber].id !== answer.id
    ) {
      const newAnswers = {}
      Object.keys(this.state.selectedAnswers).forEach((key) => {
        if (key < this.currentQuestionNumber) {
          newAnswers[key] = this.state.selectedAnswers[key]
        }
      })
      selectedAnswers = {
        ...newAnswers,
        [this.currentQuestionNumber]: {
          ...answer,
          question: getLocalizedProperty(question, 'value'),
        },
      }
    } else {
      selectedAnswers = {
        ...this.state.selectedAnswers,
        [this.currentQuestionNumber]: {
          ...answer,
          question: getLocalizedProperty(question, 'value'),
        },
      }
    }

    this.setState({ selectedAnswers })
  }

  nextQuestion = () => {
    let currentQuestion = this.currentQuestion
    this.currentQuestionNumber += 1

    if (
      this.currentQuestion &&
      this.currentQuestion.id === (this.state.currentQuestion || this.props.context.state.flow.question).id
    ) {
      currentQuestion = this.state.selectedAnswers[this.currentQuestionNumber - 1].question
    }

    if (currentQuestion.type === 'url' && this.props.onNextQuestion) {
      this.props.onNextQuestion(currentQuestion)
    } else {
      this.setState({ currentQuestion, animateContent: false }, () => {
        setTimeout(() => {
          this.setState({ animateContent: true })
        }, 0)
      })

      if (this.props.onNextQuestion) {
        this.props.onNextQuestion(currentQuestion)
      }
    }
  }

  previousQuestion = () => {
    const { flow } = this.props.context.state
    let currentQuestion = flow

    this.currentQuestionNumber -= 1

    for (let i = 0; i < this.currentQuestionNumber; i++) {
      if (i === 0) {
        currentQuestion = currentQuestion.question
      } else {
        const answerId = this.state.selectedAnswers[i].id
        currentQuestion = currentQuestion.answers.find((ans) => ans.id === answerId).question
      }
    }

    this.currentQuestion = currentQuestion

    this.setState({ currentQuestion, animateContent: false }, () => {
      setTimeout(() => {
        this.setState({ animateContent: true })
      }, 0)
    })
  }

  buildQuote = () => {
    this.setState({ buildQuote: true })
  }

  renderCard = ({ Header, Body, Footer, question }) => {
    const { selectedAnswers, buildQuote, animateContent } = this.state
    const {
      submitProposal,
      RenderHelpText,
      hideHelpText = false,
      asDoc = true,
      asPdf = true,
      showVMSButton = false,
    } = this.props
    const questionValues = getLocalizedProperty(question, 'value').split('  ')
    const header = questionValues[0]
    const { answers = [] } = question
    const { displaySelectedIcon } = this.props.context.theme.components.decisionTree
    const values = questionValues.slice(1).filter((v) => {
      if (v && !v.includes('[object Object]')) {
        return v
      }
      return false
    })

    return (
      <DecisionTreeContainer>
        <Header className="decision-tree-header">
          <HeaderContainer>{buildQuote ? i18n('decisionTree.quoteBuilder.buildQuoteHeader') : header}</HeaderContainer>
        </Header>
        {buildQuote && (
          <BuildQuote
            Body={Body}
            Footer={Footer}
            asDoc={asDoc}
            asPdf={asPdf}
            showVMSButton={showVMSButton}
            submitProposal={submitProposal}
            animateContent={animateContent}
            DecisionTreeBody={DecisionTreeBody}
            DecisionTreeFooter={DecisionTreeFooter}
            resetDecisionTree={this.resetDecisionTree}
            question={question}
            className="build-quote"
          />
        )}
        {!buildQuote && (
          <>
            <Body verticalScroll>
              <DecisionTreeBody className={animateContent ? 'toggleAnimation' : ''}>
                {values.length > 0 && (
                  <div
                    className={
                      !question.final
                        ? 'question'
                        : !question.noQuote
                        ? 'question question-final'
                        : 'question question-final no-quote'
                    }
                  >
                    {values.map((value, index) => {
                      return <p key={index}>{value}</p>
                    })}
                  </div>
                )}

                {question.final && this.props.sowBuilderSummaryText && (
                  <PrimaryText>
                    <MediumText className="question-summary">{this.props.sowBuilderSummaryText}</MediumText>
                  </PrimaryText>
                )}

                {question.final &&
                  !question.noQuote &&
                  Object.keys(selectedAnswers).map((key) => (
                    <AnswersSummary key={selectedAnswers[key].id}>
                      <p className="question-summary">{selectedAnswers[key].question.split('  ').join(' ')}</p>
                      <p className="answer-summary">{getLocalizedProperty(selectedAnswers[key], 'value')}</p>
                    </AnswersSummary>
                  ))}

                {!question.final && (
                  <>
                    {answers.length > 0 &&
                      answers.map((answer) => {
                        const selectedAnswer = (selectedAnswers[this.currentQuestionNumber] || {}).id === answer.id

                        if (selectedAnswer) {
                          const noQuote = answer.help && answer.help.toLowerCase() === 'noquote'
                          const isProduct = answer.childType === 'product'
                          const isProductMessage = `Final Step  ${answer.help || ''}`
                          const finalStepValue = `Final Step  ${answer.childValue}`

                          const finalQuestion = {
                            value: isProduct ? isProductMessage : !noQuote ? finalStepValue : `${answer.childValue}`,
                            productId: isProduct ? answer.childValue : '',
                            type: answer.childType,
                            childValue: answer.childValue,
                            products: answer.products,
                            bundles: answer.bundles,
                            final: true,
                            noQuote,
                            answer,
                          }

                          this.currentQuestion = question.answers.find((ans) => ans.id === answer.id).question || {
                            ...finalQuestion,
                          }
                          this.currentAnswer = question.answers.find((ans) => ans.id === answer.id)
                        }

                        return (
                          <Button
                            key={answer.id}
                            className={selectedAnswer ? 'selected answer-button' : 'answer-button'}
                            onClick={() => this.answerSelected(answer, question)}
                          >
                            <AnswerText>
                              {getLocalizedProperty(answer, 'value')}
                              {!hideHelpText && answer.help && <RenderHelpText answer={answer} />}
                            </AnswerText>
                            <AnswerIcon>
                              {displaySelectedIcon && selectedAnswer && (
                                <FaCheck
                                  size={18}
                                  color={
                                    this.props.context.theme.components.decisionTree.answers.selectedAnswerColor ||
                                    this.props.context.theme.components.decisionTree.answers.color
                                  }
                                />
                              )}
                            </AnswerIcon>
                          </Button>
                        )
                      })}

                    {!answers.length > 0 && <p>There are no answers defined for this question.</p>}
                  </>
                )}
              </DecisionTreeBody>
            </Body>
          </>
        )}

        <Footer className="decision-tree-footer">
          <DecisionTreeFooter>
            <Button
              disabled={this.currentQuestionNumber === 1}
              onClick={() => {
                if (buildQuote) this.setState({ buildQuote: false })
                else this.previousQuestion()
              }}
            >
              {!question.final
                ? i18n('decisionTree.quoteBuilder.previousQuestion')
                : i18n('decisionTree.quoteBuilder.goBack')}
            </Button>

            {!question.noQuote && (
              <>
                {question.final ? (
                  (this.props.startOverOnMessageEnd && question.type === 'message') || buildQuote ? (
                    <Button onClick={this.resetDecisionTree}>
                      <MdReplay /> {i18n('decisionTree.quoteBuilder.startOver')}
                    </Button>
                  ) : (
                    <Button onClick={this.buildQuote}>{i18n('decisionTree.quoteBuilder.buildQuote')}</Button>
                  )
                ) : (
                  <Button
                    disabled={!this.state.selectedAnswers[this.currentQuestionNumber]}
                    onClick={this.nextQuestion}
                  >
                    {i18n('decisionTree.quoteBuilder.nextQuestion')}
                  </Button>
                )}
              </>
            )}
          </DecisionTreeFooter>
        </Footer>
      </DecisionTreeContainer>
    )
  }

  render() {
    const { decisionTreeFlowsFetch, context } = this.props
    const { currentQuestion = context.state.flow.question } = this.state

    if (decisionTreeFlowsFetch.pending) {
      return <Progress />
    } else if (decisionTreeFlowsFetch.rejected) {
      return (
        <Grid>
          <MediumText>
            <CenteredText>
              <EngravedText>
                <MutedText>An error occurred while loading the decision tree :(</MutedText>
              </EngravedText>
            </CenteredText>
          </MediumText>
        </Grid>
      )
    } else if (decisionTreeFlowsFetch.value === null) {
      return (
        <Grid>
          <MediumText>
            <CenteredText>
              <EngravedText>
                <MutedText>Currently there is no decision tree available</MutedText>
              </EngravedText>
            </CenteredText>
          </MediumText>
        </Grid>
      )
    }

    return (
      <Card noBorder={this.props.noBorder}>{(props) => this.renderCard({ ...props, question: currentQuestion })}</Card>
    )
  }
}

export default withContext(
  connectRequest(({ flowId, context }) => ({
    decisionTreeFlowsFetch: flowId
      ? requests.fetchDecisionTreeFlowById(flowId)
      : requests.fetchDecisionTreeFlows(context.state.selectedCatalogId),
  }))(DecisionTree),
)
