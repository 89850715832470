import React from 'react'
import { BsDownload } from 'react-icons/bs'
import { styled, Button } from '@tools/wr-catalog-base'

const Container = styled.div`
  background-color: ${({ theme }) => theme.components.hero.backgroundColor};
  width: 100%;

  margin-top: ${({ theme }) => '-' + theme.components.hero.marginBottom};
  margin-bottom: ${({ theme }) => theme.components.hero.marginBottom};
  padding-bottom: 70px;
  text-align: center;
  font-size: 22px;
  * {
    color: white;
  }
`

const DownloadButton = styled(Button)`
  border: 1px solid white;
  display: inline-block;
  text-decoration: none;

  .link-label {
    margin-left: 10px;
  }
`

export default function SubHero() {
  return (
    <Container>
      <DownloadButton
        as="a"
        target="_blank"
        rel="noopener"
        href="https://oneteamsource.seismic.com/Link/Content/DC_TfAnMm8kk-cRH7gBur5gg"
      >
        <BsDownload />
        <span className="link-label">Download the catalog PPT for your industry</span>
      </DownloadButton>
      <br />
      <br />
      <div>
        Got ideas to improve the catalog?{' '}
        <a href="https://general.ideas.aha.io/" rel="noopener noreferrer" target="_blank">
          Submit them here!
        </a>
      </div>
    </Container>
  )
}
