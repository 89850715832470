import List from './views/List'
import ProductView from './views/Product'
import SpecialProductView from './views/SpecialProduct'
import ProposalsView from './views/Proposals'
import LoginView from './views/Login'
import DecisionTreeView from './views/DecisionTree'
import TokenRedirect from './views/TokenRedirect'

export default [
  {
    path: '/',
    key: 'index',
    exact: true,
    component: List,
  },
  {
    path: '/product/:productId',
    key: 'productDetail',
    exact: true,
    component: ProductView,
  },
  {
    path: '/landing-page/:productId',
    key: 'specialProductDetail',
    exact: true,
    component: SpecialProductView,
  },
  {
    path: '/bundle/:bundleId',
    key: 'bundleDetail',
    exact: true,
    component: ProductView,
  },
  {
    path: '/proposals',
    key: 'proposals',
    exact: true,
    component: ProposalsView,
  },
  {
    path: '/login',
    key: 'login',
    exact: true,
    component: LoginView,
  },
  {
    path: '/decisionTree/:flowId?',
    key: 'decisionTree',
    exact: true,
    component: DecisionTreeView,
  },
  {
    path: '/token/:token',
    key: 'tokenRedirect',
    exact: true,
    component: TokenRedirect,
  },
]
