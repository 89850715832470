import React, { useEffect } from 'react'
import { LoginForm, Button, FullHeightRow, SpacedCol, FullHeightGrid, context } from '@tools/wr-catalog-base'

const handleLogIn = () => {
  const currentLocation = window.location.hash
  if (currentLocation && currentLocation !== '#/login') {
    window.sessionStorage.setItem('redirectPath', currentLocation)
  }

  window.location.href = `${process.env.REACT_APP_AD_SAML_REDIRECT}?response_type=code&client_id=${process.env.REACT_APP_AD_CLIENT_ID}&tenant=67bff79e-7f91-4433-a8e5-c9252d2ddc1d&scope=openid`
}

const LoginView = () => {
  useEffect(() => {
    if (context.constants.autodeskAccessToken) {
      context.history.push(context.getRoutePath('index'))
    }
  })

  return (
    <FullHeightGrid fluid>
      <FullHeightRow middle="xs" center="xs">
        <SpacedCol lg={3} md={6} sm={9} xs={12}>
          {process.env.REACT_APP_USE_WR_LOGIN === 'true' ? (
            <LoginForm />
          ) : (
            <Button large onClick={handleLogIn}>
              Log In
            </Button>
          )}
        </SpacedCol>
      </FullHeightRow>
    </FullHeightGrid>
  )
}

export default LoginView
