/* eslint-disable import/no-unused-modules */
import { context } from '@tools/wr-catalog-base'

// Invalidate all logins from before this change
// to force user to re-login and make sure we capture all required login info
const cutOffDate = new Date(2020, 10, 10).getTime()

try {
  const autodeskAccessToken = context.persistentStorage.get('autodeskAccessToken')
  const issuedAt = JSON.parse(window.atob(autodeskAccessToken.split('.')[1])).iat * 1000
  if (issuedAt < cutOffDate) {
    context.persistentStorage.remove('autodeskAccessToken')
    context.persistentStorage.remove('token')
  }
} catch (err) {}
