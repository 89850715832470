import React, { Component } from 'react'
import {
  Row,
  Col,
  Hero,
  Title,
  styled,
  SplitView,
  ProductList,
  FullHeightGrid,
  SpacedCol,
  context,
} from '@tools/wr-catalog-base'
import { submitProposal } from '../../utils/proposals/submit-proposal'
import DecisionTree from '../../components/decision-tree'

const DecisionTreeContainer = styled.div`
  .decision-tree-header {
    border-bottom: 1px solid #ddd;
  }

  .decision-tree-footer {
    border-top: 1px solid #ddd;
  }

  input,
  textarea,
  .react-date-picker__wrapper {
    border-radius: 5px;
  }

  .react-date-picker__wrapper {
    color: #333;
  }

  .react-date-picker__calendar-button svg {
    fill: #333;
  }
`

const StyledList = styled.div`
  [class^='toolbar__'] input:focus {
    border-color: #ced4da;
    box-shadow: none;
    outline: none;
  }

  .split-view-switcher {
    border-bottom: 1px solid #0477a8;
  }

  .product-name {
    font-size: 1.2em;
    line-height: 1.4em;
    font-weight: 600;
  }

  .split-view-switcher li {
    border: none;
    background-color: transparent;
    color: #333;
  }

  .split-view-switcher li:first-child {
    border-right: none;
  }

  .split-view-switcher li.active {
    border-bottom: 3px solid #0477a8;
    background-color: transparent;
    color: #0477a8;
  }

  .category-filter {
    margin-top: 10px;
    border-radius: 20px;
  }

  .category-filter:hover {
    background-color: rgba(248, 151, 29, 0.1);
  }

  .category-filter.selected:hover {
    opacity: 0.8;
    background-color: rgba(248, 151, 29, 1);
  }

  .category-filter,
  .category-filter:hover {
    transition: background-color 0.3s, opacity 0.3s, color 0.3s;
  }

  .products-container > button {
    width: 100%;
    border-radius: 5px;
    padding: 12px;
  }

  .product-description {
    max-height: 200px;
    overflow-y: scroll;
    padding-bottom: 0;
    margin-bottom: 1.5rem;
  }

  .title {
    margin: 50px 0 20px;
    text-align: center;
    font-size: 25px;
    color: #0477a8;
  }
`

const SubTitle = styled.p`
  line-height: 22px;
  margin-bottom: 40px;
`

class DecisionTreeView extends Component {
  componentDidMount() {
    context.actions.logIndexTransaction()
    this.toggle()
  }

  onReset = () => {
    context.history.push(context.getRoutePath('decisionTree'))
  }

  getDecisionTreeReset = (resetDecisionTree) => {
    this.resetDecisionTree = resetDecisionTree
  }

  getToggleView = (toggle) => {
    this.toggle = toggle
  }

  onNextQuestion = (q) => {
    if (q.final && q.type === 'flow' && q.answer && q.answer.childValue) {
      context.history.push(context.getRoutePath('decisionTree', { flowId: q.answer.childValue }))
    }

    if (q.final && q.type === 'catalog') {
      this.resetDecisionTree()
      this.toggle('main')
    }

    if (q.final && q.type === 'url' && q.answer.childValue) {
      window.location = q.answer.childValue
    }
  }

  getDecisionTreeReset = (resetDecisionTree) => {
    this.resetDecisionTree = resetDecisionTree
  }

  render() {
    const colSize = {
      lg: 8,
      md: 10,
      sm: 12,
      xs: 12,
    }

    const {
      match: {
        params: { flowId },
      },
    } = this.props

    return (
      <StyledList>
        <Hero />

        <SplitView
          mainLabel="Search/Explore Accelerators"
          secondaryLabel="Outcomes Driven Accelerator Discovery"
          getToggleView={this.getToggleView}
          mainView={false}
        >
          {({ MainView, SecondaryView }) => (
            <>
              <MainView>
                <ProductList
                  toolBarColSize={colSize}
                  searchableKeys={['name', 'supplierName']}
                  categoriesColSize={colSize}
                  toolbarType={'categoriesDropdown'}
                  showLayoutButtons
                  showParentChildCatergories
                  showBundles
                  showFilters
                  showAddedToOrderIcon
                  toolbar
                />
              </MainView>

              <SecondaryView>
                <Title className="title">Accelerator Discovery</Title>
                <Row middle="xs" center="xs">
                  <Col lg={8} md={8} sm={12} xs={12}>
                    <SubTitle>
                      Welcome to the Autodesk Accelerator Discovery Portal. Answer the following questions and we will
                      guide you to the accelerator(s) that are right for your customer.
                    </SubTitle>
                  </Col>
                </Row>
                <DecisionTreeContainer>
                  <FullHeightGrid>
                    <Row center="xs">
                      <SpacedCol xs={12} className="product-form-page">
                        <DecisionTree
                          key={flowId || '874e614e-dca1-4410-aed5-b9765f52b99d'}
                          flowId={flowId || '874e614e-dca1-4410-aed5-b9765f52b99d'}
                          onNextQuestion={this.onNextQuestion}
                          onReset={this.onReset}
                          getDecisionTreeReset={this.getDecisionTreeReset}
                          submitProposal={submitProposal}
                          startOverOnMessageEnd
                        />
                      </SpacedCol>
                    </Row>
                  </FullHeightGrid>
                </DecisionTreeContainer>
              </SecondaryView>
            </>
          )}
        </SplitView>
      </StyledList>
    )
  }
}

export default DecisionTreeView
